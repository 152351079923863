exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agenda-js": () => import("./../../../src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-archief-js": () => import("./../../../src/pages/archief.js" /* webpackChunkName: "component---src-pages-archief-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-organiseren-js": () => import("./../../../src/pages/organiseren.js" /* webpackChunkName: "component---src-pages-organiseren-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-projecten-js": () => import("./../../../src/pages/projecten.js" /* webpackChunkName: "component---src-pages-projecten-js" */),
  "component---src-pages-studio-js": () => import("./../../../src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-wat-is-govio-js": () => import("./../../../src/pages/wat-is-govio.js" /* webpackChunkName: "component---src-pages-wat-is-govio-js" */),
  "component---src-templates-event-page-js": () => import("./../../../src/templates/event-page.js" /* webpackChunkName: "component---src-templates-event-page-js" */),
  "component---src-templates-project-page-js": () => import("./../../../src/templates/project-page.js" /* webpackChunkName: "component---src-templates-project-page-js" */)
}

